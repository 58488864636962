<div class="loader_common_main_wrapper" *ngIf="loader">
    <div class="loader"></div>
</div>

<div id="login" fxLayout="column">
    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">
        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}" style="background-color: #ffffff;">
            <div class="logo">
                <h2 style="color: #039be5;"><b>RESUME BUILDER</b></h2>
            </div>
            <div class="title">LOGIN</div>
            <form name="loginForm" [formGroup]="loginForm" novalidate>

                <mat-form-field appearance="outline">
                    <mat-label>Username</mat-label>
                    <input matInput formControlName="username" autocomplete="off" />
                    <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                    <mat-error *ngIf="loginForm.get('username').hasError('required')">
                        Username is required
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance = "outline">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" formControlName="password" placeholder="Minimum Length 6" autocomplete="off" #x/>
                    <mat-icon matSuffix class="secondary-text" style="cursor: pointer;" (click)="x.type=x.type=='password' ?'text':'password';onChange()" *ngIf="iconCheck">
                        visibility icon
                    </mat-icon>
                    <mat-icon matSuffix class="secondary-text" style="cursor: pointer;" (click)="x.type=x.type=='text' ?'password':'text';onChange()" *ngIf="!iconCheck">
                        visibility_off
                    </mat-icon>
                    
                    <mat-error>
                        Password is required
                    </mat-error>
                </mat-form-field>

                <div class="remember-forgot-password text-center" fxLayout="row" fxLayout.xs="column"
                     fxLayoutAlign="space-between center">
                    <!-- <mat-checkbox class="remember-me" aria-label="Remember Me">
                        Remember Me
                    </mat-checkbox> -->

                    <!-- <a class="forgot-password"  [routerLink]="'/forgotpassword'">
                        Forgot Password?
                    </a> -->
                </div>
                <div class="loginfailed" *ngIf="loginMessage === 'Failed to Login'">
                    Failed to Login
                </div>
                <button mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                        [disabled]="loginForm.invalid" (click)="loginUser()">
                    LOGIN
                </button>

            </form>

            <!-- <div class="separator">
                <span class="text">OR</span>
            </div> -->

            <!-- <button mat-raised-button class="google">
                Log in with Google
            </button>

            <button mat-raised-button class="facebook">
                Log in with Facebook
            </button> -->

            <!-- <div class="register" fxLayout="column" fxLayoutAlign="center center">
                <span class="text">Don't have an account?</span>
                <a class="link" [routerLink]="'/register'">Create an account</a>
            </div> -->

        </div>

    </div>

</div>
<div class="spinner-wrapper" *ngIf="loader">
    <div class="spinner">
        <div class="inner">
            <div class="gap"></div>
            <div class="left">
                <div class="half-circle"></div>
            </div>
            <div class="right">
                <div class="half-circle"></div>
            </div>
        </div>
    </div>
</div>
