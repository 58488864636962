export const getLocalStorageItem = (id: string): any => JSON.parse(localStorage.getItem(id));
export const setLocalStorageItem = (id: string, data: any): void => localStorage.setItem(id, JSON.stringify(data));
export const delLocalStorageItem = (id: string): void => localStorage.removeItem(id);
export const getToken = () => getLocalStorageItem(AUTH_TOKEN);


/* ---------------------------- LOCALSTORAGE KEYS --------------------------- */

export const AUTH_TOKEN = 'auth-data';
export const USER_ID = 'USER_ID';
