import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  baseUrl: string = environment.apiUrl;

  constructor(private apiService: ApiService) {}

  login(credentials: any) {
    return this.apiService.postData('api/login', credentials);
  }

  register(credentials: any) {
    return this.apiService.postData('api/referralAgent/signup', credentials);
  }
}
