import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private subject = new Subject<any>();
  baseUrl: string = environment.apiUrl;

  constructor(private http: HttpClient) {}

  postData(endpoint: string, data: any) {
    return this.http.post(`${this.baseUrl}${endpoint}`, data).pipe(map((response: any) => response));
  }

  getData(endpoint: string) {
    return this.http.get(`${this.baseUrl}${endpoint}`).pipe(map((response: any) => response));
  }

  updateData(endpoint: string, data: any) {
    return this.http.put(`${this.baseUrl}${endpoint}`, data).pipe(map((response: any) => response));
  }

  putData(endpoint: string, data: any) {
    return this.http.put(`${this.baseUrl}${endpoint}`, data).pipe(map((response: any) => response));
  }

  deleteData(endpoint: string) {
    return this.http.delete(`${this.baseUrl}${endpoint}`).pipe(map((response: any) => response));
  }

  getNavbar(status: any) {
    this.subject.next({ text: status });
  }

  getNavstatus(): Observable<any> {
    return this.subject.asObservable();
  }
}
