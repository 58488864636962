import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {
        id: "home",
        title: "Home",
        translate: "NAV.APPLICATIONS",
        type: "group",
        children: [
            {
                id: "employees",
                title: "Employees",
                translate: "NAV.SAMPLE.TITLE",
                type: "item",
                icon: "dashboard",
                url: "/employees",
            },
        ],
    },
    {
        id: "projects",
        title: "Projects",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "dashboard",
        url: "/projects",
    },
    {
        id: "skills",
        title: "Skills",
        translate: "NAV.SAMPLE.TITLE",
        type: "item",
        icon: "dashboard",
        url: "/skills",
    },
];
