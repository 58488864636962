import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
import { CustomHttpInterceptor } from './shared/http.interceptor';

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from "@fuse/components";

import { fuseConfig } from "app/fuse-config";

import { AccessAuthGuard } from "app/services/auth.guard";

import { AppComponent } from "app/app.component";
import { LoginComponent } from "app/pages/login/login.component";

import { LayoutModule } from "app/layout/layout.module";
import { HomeModule } from "app/pages/home/home.module";
import { DashboardComponent } from "./pages/home/dashboard/dashboard.component";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import {MatDatepickerModule} from '@angular/material/datepicker';


const appRoutes: Routes = [
    {
        path: "",
        redirectTo: "/login",
        pathMatch: "full",
    },
    {
        path: "login",
        component: LoginComponent,
    },
    {
        path: "skills",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/skills/skills.module").then((m) => m.SkillsModule),
    },
    {
        path: "projects",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/projects/projects.module").then((m) => m.ProjectsModule),
    },
    {
        path: "employees",
        canActivate: [AccessAuthGuard],
        loadChildren: () => import("./pages/employees/employees.module").then((m) => m.EmployeesModule),
    },
];

@NgModule({
    declarations: [AppComponent, LoginComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CustomHttpInterceptor,
            multi: true,
        },
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        HomeModule,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
