import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class HelperService {
    private auth = new BehaviorSubject(false);

    constructor(private snackBar: MatSnackBar) {}

    setAuth(status: boolean = false) {
        this.auth.next(status);
    }

    getAuth() {
        return this.auth.asObservable();
    }

    showToast(msg: string, action: string = "Okay", time: number = 3) {
        this.snackBar.open(msg || "Something went wrong", action, {
            duration: time * 1000,
        });
    }
}
