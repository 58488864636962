import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';

import { LoginService } from '../../services/login.service';
import { setLocalStorageItem } from 'app/helpers/utils';
import { HelperService } from 'app/services/helper.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})

export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loader = false;
  loginMessage: any;
  iconCheck: boolean = true;

  constructor(
    private _fuseConfigService: FuseConfigService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _loginService: LoginService,
    private _helperService: HelperService,
    private _apiService: ApiService
  ) {
    // Configure the layout
    this._fuseConfigService.config = {
      layout: {
        navbar: { hidden: true },
        toolbar: { hidden: true },
        footer: { hidden: true },
        sidepanel: { hidden: true },
      },
    };
  }

  ngOnInit(): void {
    this.loginForm = this._formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
    // localStorage.clear();
  }

  goToPage(path: string, data: any = null) {
    this._router.navigateByUrl(path, { queryParams: data, replaceUrl: true });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  onChange(){
    this.iconCheck = !this.iconCheck;

  }

  // Common Login Page
  loginUser(): void {
    localStorage.clear();

    if (this.loginForm.valid) {
      this.loader = true;

      const passData = {
        username: this.loginForm.value.username,
        password: this.loginForm.value.password,
      };

      this._loginService.login(passData).subscribe(
        (data: any) => {
          if (data?.status === 'success') {
            const {
              token,
              data: { username, id, emailId },
            } = data.data || {};
            setLocalStorageItem('auth-data', { token } );
            setLocalStorageItem('userAuthKey', data.data);

            this.loginMessage = data.message;
            this._router.navigateByUrl('/employees');
          } else this.loginMessage = data.message;
        },
        err => {
          console.log(err);
          this.loader = false;
          this.loginMessage = 'Failed to Login';
        }
      );
    }
  }
}
